<mat-toolbar color="primary" [ngClass]="{ 'mat-elevation-z6': !showMode() }">
  <div class="container-fluid p-0">
    <div class="row no-gutters align-items-center">
      <div class="col-auto d-block d-md-none">
        <button mat-icon-button (click)="sideNavigationService.toggle('left')">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <div class="col">
        <div class="row no-gutters align-items-center">
          <div class="col-auto">
            <a class="navbar-brand">
              <img src="../../../assets/images/theater-light.svg" width="60" height="60" />
            </a>
            <span class="version">v{{ version }}</span>
          </div>
        </div>
      </div>
      <div class="col-auto d-none d-md-block">
        <div class="form-row">
          <div class="col-auto">
            <button routerLink="/reservations" routerLinkActive="router-active" mat-button>
              <mat-icon class="mr-1">event_seat</mat-icon>
              <span>Reservieren</span>
            </button>
          </div>
          <ng-template appHasAnyRole="ADMIN">
            <div class="col-auto">
              <button routerLink="/dashboard" routerLinkActive="router-active" mat-button>
                <mat-icon class="mr-1">dashboard</mat-icon>
                <span>Dashboard</span>
              </button>
            </div>
            <div class="col-auto" *ngFor="let entity of entities; trackBy: trackByEntity">
              <button [routerLink]="[entity.link]" routerLinkActive="router-active" mat-button>
                <mat-icon class="mr-1">{{ entity.icon }}</mat-icon>
                <span>{{ entity.translateKey | translate }}</span>
              </button>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="col-auto">
        <div class="row">
          <div class="col-auto"></div>
          <div class="col-auto">
            <button id="settings" routerLink="/settings" routerLinkActive="router-active" mat-icon-button>
              <mat-icon>settings</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-toolbar>
