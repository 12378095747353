<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <div class="container-fluid p-0">
    <div class="row no-gutters justify-content-between align-items-center">
      <div class="col">{{ 'OLD_PLAN.TITLE' | translate }}</div>
      <div class="col-auto">
        <button mat-icon-button (click)="expanded = !expanded">
          <mat-icon *ngIf="!expanded">fullscreen</mat-icon>
          <mat-icon *ngIf="expanded">fullscreen_exit</mat-icon>
        </button>
        <button mat-icon-button mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<mat-dialog-content>
  <div [class.expanded]="expanded" [class.collapsed]="!expanded">
    <img src="../../assets/images/old-plan.png" />
  </div>
</mat-dialog-content>
