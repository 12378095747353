<div mat-dialog-title>
  <div class="container-fluid p-0">
    <div class="row no-gutters justify-content-between align-items-center">
      <div class="col">Anleitungen</div>
      <div class="col-auto">
        <button mat-icon-button mat-dialog-close>
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
<mat-dialog-content class="p-0" style="margin-top: -16px">
  <mat-tab-group [dynamicHeight]="true">
    <mat-tab>
      <ng-template matTabLabel
        >Reservierung <br />
        erstellen</ng-template
      >
      <ng-template matTabContent>
        <div class="container-fluid py-3">
          <div class="row">
            <div class="col">
              <iframe
                src="https://app.tango.us/app/embed/9b6a2fc6-347d-47a6-8d2b-8e6f59fcbb6c"
                style="min-height: 600px"
                sandbox="allow-scripts allow-top-navigation-by-user-activation allow-popups allow-same-origin"
                security="restricted"
                title="Wie erstelle ich eine Reservierung?"
                width="100%"
                height="100%"
                referrerpolicy="strict-origin-when-cross-origin"
                frameborder="0"
                webkitallowfullscreen="webkitallowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                allowfullscreen="allowfullscreen"
              ></iframe>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template matTabLabel
        >Reservierung <br />
        bearbeiten</ng-template
      >
      <ng-template matTabContent>
        <div class="container-fluid py-3">
          <div class="row">
            <div class="col">
              <iframe
                src="https://app.tango.us/app/embed/3184da79-94ec-45cc-a8b2-cbf33c793e28"
                style="min-height: 600px"
                sandbox="allow-scripts allow-top-navigation-by-user-activation allow-popups allow-same-origin"
                security="restricted"
                title="Wie ändere ich die Daten einer Reservierung, z.B. den Namen?"
                width="100%"
                height="100%"
                referrerpolicy="strict-origin-when-cross-origin"
                frameborder="0"
                webkitallowfullscreen="webkitallowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                allowfullscreen="allowfullscreen"
              ></iframe>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template matTabLabel>Plätze zu einer <br />Reservierung hinzufügen</ng-template>
      <ng-template matTabContent>
        <div class="container-fluid py-3">
          <div class="row">
            <div class="col">
              <iframe
                src="https://app.tango.us/app/embed/ea446c18-cd38-4ff4-a7e3-3bbf6c768724"
                style="min-height: 600px"
                sandbox="allow-scripts allow-top-navigation-by-user-activation allow-popups allow-same-origin"
                security="restricted"
                title="Wie füge ich Plätze zu einer bestehenden Reservierung hinzu?"
                width="100%"
                height="100%"
                referrerpolicy="strict-origin-when-cross-origin"
                frameborder="0"
                webkitallowfullscreen="webkitallowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                allowfullscreen="allowfullscreen"
              ></iframe>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template matTabLabel>Reservierung <br />importieren</ng-template>
      <ng-template matTabContent>
        <div class="container-fluid py-3">
          <div class="row">
            <div class="col">
              <iframe
                src="https://app.tango.us/app/embed/30edf2c7-ad37-4f8b-b1b9-0029d7991258"
                style="min-height: 600px"
                sandbox="allow-scripts allow-top-navigation-by-user-activation allow-popups allow-same-origin"
                security="restricted"
                title="Reservierung importieren"
                width="100%"
                height="100%"
                referrerpolicy="strict-origin-when-cross-origin"
                frameborder="0"
                webkitallowfullscreen="webkitallowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                allowfullscreen="allowfullscreen"
              ></iframe>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template matTabLabel>Platz einer <br />Reservierung löschen</ng-template>
      <ng-template matTabContent>
        <div class="container-fluid py-3">
          <div class="row">
            <div class="col">
              <iframe
                src="https://app.tango.us/app/embed/b28d6054-7451-4d1a-b651-cd36d3c05735"
                style="min-height: 600px"
                sandbox="allow-scripts allow-top-navigation-by-user-activation allow-popups allow-same-origin"
                security="restricted"
                title="Wie lösche ich einen Platz einer Reservierung?"
                width="100%"
                height="100%"
                referrerpolicy="strict-origin-when-cross-origin"
                frameborder="0"
                webkitallowfullscreen="webkitallowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                allowfullscreen="allowfullscreen"
              ></iframe>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
    <mat-tab>
      <ng-template matTabLabel>Reservierung <br />löschen</ng-template>
      <ng-template matTabContent>
        <div class="container-fluid py-3">
          <div class="row">
            <div class="col">
              <iframe
                src="https://app.tango.us/app/embed/a7784b67-30aa-45f3-bfd3-d0225f1862e0"
                style="min-height: 600px"
                sandbox="allow-scripts allow-top-navigation-by-user-activation allow-popups allow-same-origin"
                security="restricted"
                title="Wie lösche ich eine Reservierung?"
                width="100%"
                height="100%"
                referrerpolicy="strict-origin-when-cross-origin"
                frameborder="0"
                webkitallowfullscreen="webkitallowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                allowfullscreen="allowfullscreen"
              ></iframe>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
