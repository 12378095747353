<div *ngIf="loading" [@fadeOut] class="app-progress-bar">
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
</div>
<app-navbar class="d-print-none" *appHasAnyRole="['ADMIN', 'USER']"></app-navbar>
<router-outlet></router-outlet>
<ng-template [appHasAnyRole]="['ADMIN', 'USER']">
  <app-footer class="d-print-none" *ngIf="(authService.user | async) && !showMode()"></app-footer>
</ng-template>
<div *ngIf="showMode()" class="d-print-none floating floating-left">
  <button mat-mini-fab class="mr-2" (click)="openHelpCenter()">
    <mat-icon>question_mark</mat-icon>
  </button>
</div>
<div *ngIf="showMode()" class="d-print-none floating floating-right">
  <button mat-mini-fab class="mr-2" (click)="sideNavigationService.printView.next(!sideNavigationService.printView.value)">
    <mat-icon *ngIf="!sideNavigationService.printView.value">print</mat-icon>
    <mat-icon *ngIf="sideNavigationService.printView.value">print_disabled</mat-icon>
  </button>
  <button mat-mini-fab [matBadgeHidden]="!chatService.unreadCount" [matBadge]="chatService.unreadCount" matBadgeColor="warn" (click)="sideNavigationService.toggle('right')">
    <mat-icon *ngIf="!sideNavigationService.rightOpen">chevron_left</mat-icon>
    <mat-icon *ngIf="sideNavigationService.rightOpen">chevron_right</mat-icon>
  </button>
</div>
