import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  NavigationEnd,
  NavigationError,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { OldPlanComponent } from './components/old-plan/old-plan.component';
import { AlertService } from './services/alert.service';
import { AuthService } from './services/auth.service';
import { ChatService } from './services/chat.service';
import { SideNavigationService } from './services/side-navigation.service';
import { HelpCenterComponent } from './components/help-center/help-center.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [trigger('fadeOut', [transition(':leave', [animate(500, style({ opacity: 0 }))])])],
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'Theater-Eberhardzell';
  public loading = false;
  private destroyed = new Subject();
  private dialogRef: MatDialogRef<HelpCenterComponent | OldPlanComponent>;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private dialog: MatDialog,
    public authService: AuthService,
    public sideNavigationService: SideNavigationService,
    public chatService: ChatService,
    public alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.destroyed)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.sideNavigationService.highlightId.next(null);
        this.sideNavigationService.highlightSeats.next(null);
      } else if (event instanceof RouteConfigLoadStart) {
        this.loading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loading = false;
      } else if (event instanceof NavigationError) {
        this.alertService.warn('ERRORS.LOAD');
        this.loading = false;
      } else if (event instanceof NavigationEnd && !event.url.includes('reservations')) {
        this.sideNavigationService.rightOpen = false;
      }
    });

    this.translate.setDefaultLang('de');
    const lang = navigator.language;
    let langKey = 'de';
    if (lang) {
      if (lang.includes('-')) {
        langKey = lang.split('-')[0];
      } else {
        langKey = lang;
      }
    }
    this.translate.use(langKey);
  }

  openHelpCenter() {
    if (this.dialogRef) {
      this.dialogRef.close();
      return;
    }
    this.dialogRef = this.dialog.open(HelpCenterComponent, {
      hasBackdrop: true,
      width: '680px',
      height: '800px',
      maxWidth: '95vw',
      maxHeight: '95vh',
    });
    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.dialogRef = null;
      });
  }

  toggleOldPlan() {
    if (this.dialogRef) {
      this.dialogRef.close();
      return;
    }
    this.dialogRef = this.dialog.open(OldPlanComponent, {
      hasBackdrop: false,
      position: {
        bottom: '32px',
        left: '32px',
      },
    });
    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.dialogRef = null;
      });
  }

  public showMode() {
    return this.router.url.includes('reservations');
  }

  ngOnDestroy(): void {
    this.destroyed.next(undefined);
    this.destroyed.complete();
  }
}
